//-------------
// Privacy Page
//-------------

// PACKAGES
import React from "react"
import Sidebar from "../layout/Terms"
import { Link } from "gatsby"

// COMPONENT BUILD
class PrivacyPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            date: new Date().toLocaleString(),
        }
    }

    // JSX TEMPLATE
    render() {
        return (
            <Sidebar>
                <article className="px-4 py-5 shadow col-lg-10 p-md-6">
                    <h1 className="mb-4">Privacy</h1>
                    <p className="mb-5">Effective date: {this.state.date}</p>
                    <p>
                    Tamas Gabor Media Ltd. (“us”, “we”, or “our”) operates the informeasy.com website (hereinafter referred to as the “Service”).
                    </p>
                    <p>
                    This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. This Privacy Policy for Informeasy is powered by PrivacyPolicies.com.
                    </p>
                    <p>
                    We collect several different types of information for various purposes to provide and improve our Service to you. By using the Service, you agree to the collection and use of information in accordance with this policy.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Types of data collected
                    </h3>
                    <p>
                        <u>
                            Personal Data
                        </u>
                    </p>
                    <p>
                    While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:
                    </p>
                    <p>
                    Email address
                    </p>
                    <p>
                    First name and last name
                    </p>
                    <p>
                    Phone number
                    </p>
                    <p>
                    Address, State, Province, ZIP/Postal code, City
                    </p>
                    <p>
                    Cookies and Usage Data
                    </p>
                    <p>
                        <u>
                            Usage Data
                        </u>
                    </p>
                    <p>
                    We may also collect information on how the Service is accessed and used (“Usage Data”). This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                    </p>
                    <p>
                        <u>
                            Tracking and Cookies Data
                        </u>
                    </p>
                    <p>
                    We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. You may read more about cookies in our <Link to="/cookies">Cookies Policy.</Link>
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Used of data
                    </h3>
                    <p>
                    Informeasy uses the collected data for various purposes:
                    </p>
                    <p>
                    To provide and maintain the Service
                    </p>
                    <p>
                    To notify you about changes to our Service
                    </p>
                    <p>
                    To allow you to participate in interactive features of our Service when you choose to do so
                    </p>
                    <p>
                    To provide customer care and support
                    </p>
                    <p>
                    To provide analysis or valuable information so that we can improve the Service
                    </p>
                    <p>
                    To monitor the usage of the Service
                    </p>
                    <p>
                    To detect, prevent and address technical issues
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Transfer of Data
                    </h3>
                    <p>
                    Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
                    </p>
                    <p>
                    If you are located outside United Kingdom and choose to provide information to us, please note that we transfer the data, including Personal Data, to United Kingdom and process it there.
                    </p>
                    <p>
                    Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                    </p>
                    <p>
                    Informeasy will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Security of Data
                    </h3>
                    <p>
                    The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Service Providers
                    </h3>
                    <p>
                    We may employ third party companies and individuals to facilitate our Service (“Service Providers”), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
                    </p>
                    <p>
                    Analytics
                    </p>
                    <p>
                    We may use third-party Service Providers to monitor and analyze the use of our Service.
                    </p>
                    <p>
                    Google Analytics
                    </p>
                    <p>
                    Advertising
                    </p>
                    <p>
                    We may use third-party Service Providers to display interest-based ads to you when you are using Facebook.
                    </p>
                    <p>
                    Facebook
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Children's Privacy
                    </h3>
                    <p>
                    Our Service does not address anyone under the age of 18 (“Children”).
                    </p>
                    <p>
                    We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Changes to this Privacy Policy
                    </h3>
                    <p>
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                    </p>
                    <p>
                    We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the “effective date” at the top of this Privacy Policy.
                    </p>
                    <p>
                    You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                    Contact Us
                    </h3>
                    <p>
                    If you have any questions about this Privacy Policy, please contact us:
                    </p>
                    <p>
                    By email: contact@informeasy.com
                    </p>
                    <p>
                    By visiting this page on our website: <Link to="/">https://informeasy.com/</Link>
                    </p>
                </article>
            </Sidebar>
        )
    }
}

export default PrivacyPage
